var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.id)?_c('div',{staticClass:"m-inventory-vehicle-assignments"},[(_vm.state.loaded)?[_c('v-card-text',[(_vm.items.users.length)?_c('v-row',_vm._l((_vm.items.users),function(assignment){return (assignment.user)?_c('v-col',{key:assignment.id,attrs:{"cols":"12","md":"6","lg":"4","xl":"3"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-list-item',{style:({ opacity: new Date(assignment.handoverDate).getTime() > new Date().getTime() || new Date(assignment.returnDate).getTime() > new Date().getTime() ? 0.5: null }),attrs:{"dense":""}},[_c('v-list-item-action',[_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v("mdi-account")])],1)],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(assignment.user.firstName)+" "+_vm._s(assignment.user.lastName))]),(assignment.vehicle.company)?_c('v-list-item-subtitle',[_vm._v(_vm._s(assignment.vehicle.company.name))]):_vm._e()],1),(!assignment.returnDate)?_c('v-list-item-action',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('action', { type: 'vehicleReturn', user: assignment.user, vehicle: assignment.vehicle, step: 4 })}}},[_c('v-icon',[_vm._v("mdi-link-off")])],1)],1):_vm._e()],1)],1)],1):_vm._e()}),1):_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-alert',{staticClass:"ma-0",attrs:{"text":"","type":"info"}},[_vm._v("Pojazd nie jest obecnie przypisany do żadnego użytkownika")])],1)],1)],1),_c('v-divider'),_c('v-toolbar',{attrs:{"flat":"","dense":""}},[_c('span',{staticClass:"text--secondary"},[_vm._v("Historia użytkowników")])]),_c('v-data-table',{attrs:{"items":_vm.items.assignments,"loading":_vm.state.loading,"headers":_vm.headers,"footer-props":{ itemsPerPageOptions: [5, 10] }},scopedSlots:_vm._u([{key:"item.user",fn:function(ref){
var item = ref.item;
return [(item.user)?_c('span',[_vm._v(_vm._s(item.user.firstName)+" "+_vm._s(item.user.lastName))]):_c('v-icon',[_vm._v("mdi-minus")])]}},{key:"item.handoverDate",fn:function(ref){
var item = ref.item;
return [(item.handoverDate)?_c('span',[_vm._v(_vm._s(_vm._f("moment")(item.handoverDate,'DD.MM.YYYY')))]):_c('v-icon',[_vm._v("mdi-minus")])]}},{key:"item.returnDate",fn:function(ref){
var item = ref.item;
return [(item.returnDate)?_c('span',[_vm._v(_vm._s(_vm._f("moment")(item.returnDate,'DD.MM.YYYY')))]):_c('v-icon',[_vm._v("mdi-minus")])]}},{key:"item.handoverAttachments",fn:function(ref){
var item = ref.item;
return [(item.handoverAttachments && item.handoverAttachments.length)?_c('a-files-list',{attrs:{"items":item.handoverAttachments}}):_c('v-icon',[_vm._v("mdi-minus")])]}},{key:"item.returnAttachments",fn:function(ref){
var item = ref.item;
return [(item.returnAttachments && item.returnAttachments.length)?_c('a-files-list',{attrs:{"items":item.returnAttachments}}):_c('v-icon',[_vm._v("mdi-minus")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-end"},[(!item.returnDate)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2",attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('action', { type: 'vehicleReturn', user: item.user, vehicle: item.vehicle, step: 4 })}}},on),[_c('v-icon',[_vm._v("mdi-link-off")])],1)]}}],null,true)},[_c('span',[_vm._v("Zdanie akcesorium")])]):_vm._e()],1)]}}],null,false,1431297592)})]:_c('v-card-text',[(_vm.state.loading)?_c('span',[_vm._v("Ładowanie...")]):_c('span',[_vm._v("Nie można załadować danych")])])],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }