




























































import useAttachment from "@/use/attachment";
import { defineComponent, reactive } from "@vue/composition-api";
import { watchDebounced } from "@vueuse/shared";
import { AxiosInstance } from "axios";

export default defineComponent({
  components: {
    AFilesList: () => import("@/components/atoms/a-files-list.vue"),
  },
  props: {
    id: {
      type: String,
      required: false,
      default: null,
    },
  },
  setup(props, { root }) {
    const { downloadAttachment } = useAttachment({ root });

    const items = reactive({
      users: [],
      assignments: [],
      total: {
        users: 0,
        assignments: 0,
      },
    });

    const headers = [
      { value: "user", text: "Użytkownik", sortable: false },
      { value: "handoverDate", text: "Data wydania", sortable: false },
      {
        value: "handoverAttachments",
        text: "Protokoły wydania",
        sortable: false,
      },
      { value: "returnDate", text: "Data zdania", sortable: false },
      { value: "returnAttachments", text: "Protokoły zdania", sortable: false },
      { value: "action", text: "", width: 1, sortable: false, align: "right" },
    ];

    const state = reactive({ loading: false, loaded: false });

    const fetch = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;
      state.loading = true;

      axiosInstance
        .get(`inventory/vehicle/${props.id}`)
        .then(
          ({
            data: { users, assignments, totalVehicles, totalAssignments },
          }) => {
            items.users = users;
            items.assignments = assignments;
            items.total.users = totalVehicles;
            items.total.assignments = totalAssignments;

            state.loaded = true;
          }
        )
        .catch(() => {
          items.users = [];
          items.assignments = [];
          items.total.users = 0;
          items.total.assignments = 0;

          state.loaded = false;
        })
        .finally(() => (state.loading = false));
    };

    watchDebounced(() => props.id, fetch, {
      deep: true,
      immediate: true,
      debounce: 500,
      maxWait: 5000,
    });

    const refresh = () => fetch();

    return { downloadAttachment, items, headers, state, refresh };
  },
});
